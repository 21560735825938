.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cardContainer {
  display: flex;
  justify-content: center;
}

.backImage {
  background-color: #61dafb;
  background-image: url("./felix-mooneeram-evlkOfkQ5rE-unsplash.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.titleImage {
  max-width: 1000px;
  min-width: 400px;
}

.starList {
  display: inline;
  list-style: none;
}

.introFont {
  font-family: Helvetica, Arial, sans-serif;
}

.starContainer {
  padding-bottom: 20px;
}

.starSet {
  width: 48px;
  height: 48px;
}

a:link {
  text-decoration: none;
  color: white;

}

a:visited {
  text-decoration: none;
  color: white;
}


a:active {
  text-decoration: none;
  color: white;
}


.introContainer {
  font-size: x-large;
}

.movieDB {
  max-width: 270px;
  padding-top: 15px;
}

.cardWidth {
  min-width: 100px;
  max-width: 400px;
}

.userName {
  font-weight: bolder;
}

.smallDate {
  font-size: x-small;
  font-weight: bolder;
}